import { History } from '@material-ui/icons';
import React, { lazy } from 'react';

import { Routes } from '../enum/routes.enum';
import { Title } from '../enum/title.enum';
import { Route } from '../types/route.interface';
import analyticsIcon from './icons/analytics.svg';
import contractorsIcon from './icons/contractors.svg';
import handbookIcon from './icons/handbook.svg';
import objectsIcon from './icons/objects.svg';
import prescriptionsIcon from './icons/prescriptions.svg';
import profileIcon from './icons/profile.svg';
import usersIcon from './icons/users.svg';

export const routes: Route[] = [
  {
    title: Title.Profile,
    sidebar: true,
    iconPath: profileIcon,
    component: lazy(() => import('../pages/Users/UserForm')),
    link: Routes.Profile,
    exact: true,
  },
  {
    title: Title.Profile,
    component: lazy(() => import('../pages/Users/UserForm')),
    link: Routes.ProfileEdit,
    exact: true,
  },
  {
    title: Title.Objects,
    link: Routes.Objects,
    sidebar: true,
    iconPath: objectsIcon,
    component: lazy(() => import('../pages/Objects')),
    exact: true,
  },
  {
    title: Title.AddObject,
    link: Routes.AddObject,
    component: lazy(() => import('../pages/Objects/AddObject')),
    exact: true,
  },
  {
    link: Routes.EditObject,
    component: lazy(() => import('../pages/Objects/EditObject')),
    exact: true,
    editable: false,
  },
  {
    link: Routes.ObjectAnalytic,
    component: lazy(() => import('../pages/Objects/ObjectAnalytics')),
    exact: true,
    editable: true,
  },
  {
    link: Routes.Object,
    component: lazy(() => import('../pages/Objects/ObjectEntity')),
    exact: true,
    editable: true,
  },
  {
    title: Title.Contractors,
    iconPath: contractorsIcon,
    component: lazy(() => import('../pages/Contractors')),
    sidebar: true,
    link: Routes.Contractors,
    exact: true,
  },
  {
    title: Title.ContractorForm,
    link: Routes.ContractorForm,
    component: lazy(() => import('../pages/Contractors/ContractorForm')),
    exact: true,
    editable: true,
  },
  {
    title: Title.ContractorForm,
    link: Routes.ContractorsEdit,
    component: lazy(() => import('../pages/Contractors/ContractorForm')),
    exact: true,
    editable: true,
  },
  {
    title: Title.MyContractors,
    iconPath: contractorsIcon,
    sidebar: true,
    link: Routes.ContractorProfile,
    component: lazy(() => import('../pages/Contractors/ContractorProfile')),
    exact: true,
    editable: true,
  },
  {
    link: Routes.ContractorAnalytics,
    component: lazy(() => import('../pages/Contractors/ContractorAnalytics')),
    exact: true,
    editable: true,
  },
  {
    title: Title.Prescriptions,
    iconPath: prescriptionsIcon,
    sidebar: true,
    component: lazy(() => import('../pages/Prescriptions')),
    link: Routes.Prescriptions,
    exact: true,
  },
  {
    component: lazy(() => import('../pages/Prescriptions/Create')),
    link: Routes.AddPrescriptionOperational,
    exact: true,
  },
  {
    component: lazy(() => import('../pages/Prescriptions/Create')),
    link: Routes.AddPrescriptionAcceptance,
    exact: true,
  },
  {
    component: lazy(() => import('../pages/Prescriptions/Create')),
    link: Routes.EditPrescriptionOperational,
    exact: true,
  },
  {
    component: lazy(() => import('../pages/Prescriptions/Create')),
    link: Routes.EditPrescriptionAcceptance,
    exact: true,
  },
  {
    component: lazy(() => import('../pages/Analytics')),
    link: Routes.PrescriptionsAnalytics,
    exact: true,
    title: Title.Analytics,
  },
  {
    component: lazy(() => import('../pages/Objects/ObjectGanttChart')),
    link: Routes.ObjectGanttChart,
    exact: true,
  },
  {
    title: Title.Analytics,
    sidebar: true,
    component: lazy(() => import('../pages/Analytics')),
    iconPath: analyticsIcon,
    link: Routes.Analytics,
    exact: true,
  },
  {
    title: Title.Handbook,
    sidebar: true,
    component: lazy(() => import('../pages/Handbook')),
    iconPath: handbookIcon,
    link: Routes.Handbook,
    exact: true,
  },
  {
    title: Title.Users,
    sidebar: true,
    component: lazy(() => import('../pages/Users')),
    iconPath: usersIcon,
    link: Routes.Users,
    exact: true,
  },
  {
    title: Title.UserForm,
    component: lazy(() => import('../pages/Users/UserForm')),
    link: Routes.UserForm,
    exact: true,
  },
  {
    title: Title.UserForm,
    component: lazy(() => import('../pages/Users/UserForm')),
    link: Routes.EditUser,
    exact: true,
  },
  {
    title: Title.UserProfile,
    component: lazy(() => import('../pages/Users/UserForm')),
    link: Routes.UserProfile,
    exact: true,
  },
  {
    title: Title.Login,
    link: Routes.Login,
    authProtection: false,
    component: lazy(() => import('../pages/Login')),
    exact: true,
  },
  {
    title: Title.Remarks,
    link: Routes.Remark,
    component: lazy(() => import('../pages/Prescriptions/Remark')),
    exact: true,
  },
  {
    title: Title.HistoryActions,
    sidebar: true,
    component: lazy(() => import('../pages/HistoryActions')),
    icon: <History />,
    link: Routes.HistoryActions,
    exact: true,
  },
  {
    title: Title.HistoryActions,
    component: lazy(
      () => import('../pages/HistoryActions/HistoryActionValues'),
    ),
    link: Routes.HistoryAction,
    exact: true,
  },
  {
    title: Title.AddGPR,
    link: Routes.AddGPR,
    component: lazy(() => import('../pages/GPR/AddGPR')),
    exact: true,
  },
  {
    title: Title.GPR,
    link: Routes.GPR,
    component: lazy(() => import('../pages/GPR')),
    exact: true,
  },
];
